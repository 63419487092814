export default {
  name: "App",
  data: () => ({//
  }),

  created() {
    /*
    if (sessionStorage.getItem("uskey") && sessionStorage.getItem("usCookie")) {
      localStorage.setItem("uskey", sessionStorage.getItem("uskey"));
      localStorage.setItem("usCookie", sessionStorage.getItem("usCookie"));
      sessionStorage.removeItem("uskey");
      sessionStorage.removeItem("usCookie");
    }
    */
  },

  mounted() {
    /*
    window.onbeforeunload = function () {
      sessionStorage.setItem("uskey", localStorage.getItem("uskey"));
      sessionStorage.setItem("usCookie", localStorage.getItem("usCookie"));
      localStorage.removeItem("uskey");
      localStorage.removeItem("usCookie");
    };
    */
  },

  watch: {}
};