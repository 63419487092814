import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c(VApp, [_c(VContainer, {
    staticClass: "grey lighten-4 pa-0",
    staticStyle: {
      "max-width": "100%"
    }
  }, [_c('router-view', {
    staticClass: "grey lighten-4"
  })], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };